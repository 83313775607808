











































































































































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { TAR_KEY_REPORT_FOR_KEY_OPT } from 'src/utils/apollo-queries';
import * as moment from 'moment';
import Vue from 'vue';
import { CHANGE_KEYWORD_BID_AMOUNT } from 'src/utils/apollo-mutations';
import { Kochava } from 'kochava';
import { LSS } from '@core/services';
import { isReadOnly} from '../utils/util';
import PermissionReadOnlyPopup from './../components/PermissionReadOnlyPopup.vue';
export default Vue.extend({
  name: 'KeywordTable',
   components:{
PermissionReadOnlyPopup
  },
  props: {
    kochava: Kochava,
  },
  created() {
    //this.kochava.page();
  },
  data() {
    return {
      permissionReadOnlyMetadata:{},
      syncStatus: false,
      locale: 'en',
      search: '',
      disableOptimizeButton: false,
      keywordsReport: null,
      keywordText: null,
      bidAmountDialog: false,
      selectionRadio: null,
      optimizeAllDialog: false,
      optimizeAllRadio: 'Min',
      saving: false,
      detailsForUpdate: {
        orgId: null,
        campaignId: null,
        adGroupId: null,
        keywordId: null,
        minBidAmount: null,
        maxBidAmount: null,
        selectedBidAmount: null,
      },
      step: 1,

      valid: true,
      e1: 1,
    };
  },

  
  computed: {
    keyheaders: {
      cache: false,
      get() {
        return [
          {
            text: this.$t('keyOpt.campaign'),
            align: 'start',
            value: 'campaignMetadata.name',
          },
          {
            text: this.$t('keyOpt.adgroup'),
            value: 'metadata.adGroupName',
          },
          {
            text: this.$t('keyOpt.key'),
            value: 'keywordMetadata.text',
          },
          {
            text: this.$t('keyOpt.bidAmt'),
            value: 'keywordMetadata.bidAmount.amount',
          },
          {
            text: this.$t('keyOpt.asaSuggestedBidAmount'),
            value: 'insights.bidRecommendation.suggestedBidAmount.amount',
          },
          {
            text: this.$t('keyOpt.action'),
            value: 'Accept',
            sortable: false,
          },
        ];
      },
    },
  },
  apollo: {
    targKeyReportsForKeyOpt: {
      query: TAR_KEY_REPORT_FOR_KEY_OPT,
      variables() {
        return {};
      },
      update(data) {
        this.getKeywordsReport(data.targKeyReportsForKeyOpt);
      },
      error(error) {
        this.$notify.error(this.$t('successMsg.wentWrongMsg'));
      },
    },
  },

  methods: {
    enableOptimizeAllDialog(){
      if(this.isReadOnly()){
        this.openPermissionReadOnlyPopup()
        return
      }
      this.optimizeAllDialog = true
    },
    isReadOnly(){
      return isReadOnly()
    },
    openPermissionReadOnlyPopup(){
      this.permissionReadOnlyMetadata={
        date: moment.now()
      }
    },
    calcEventCount(item, field) {
      return Number(item[field]?.content?.eventCount?.count || 0);
    },    
    getKeywordsReport(keywords) {
      let tempKeywordReports = [];
      for (const key of keywords.row) {
        const suggestedBidAmount = key.insights?.bidRecommendation?.suggestedBidAmount?.amount;
        if (!key.campaignMetadata?.deleted) {
          if (
            key.keywordMetadata.bidAmount.amount != suggestedBidAmount
          ) {
            tempKeywordReports.push(key);
          }
        }
      }
      if (tempKeywordReports.length == 0) {
        this.disableOptimizeButton = true;
      }
      let reportsSchema = {
        row: tempKeywordReports,
      };
      this.keywordsReport = reportsSchema;
    },

    bidAmount(item) {
      if(this.isReadOnly()){
        this.openPermissionReadOnlyPopup()
        return
      }
      this.selectionRadio = 'Min';
      this.keywordText = item.keywordMetadata.text;
      this.bidAmountDialog = true;
      this.detailsForUpdate = {
        orgId: item.metadata.orgId,
        campaignId: item.metadata.campaignId,
        adGroupId: item.metadata.adGroupId,
        keywordId: item.keywordMetadata.id,
        suggestedBidAmount: item.insights.bidRecommendation.suggestedBidAmount,
      };
    },

    async changeKeywordBidAmount() {
      this.saving = true;
      this.detailsForUpdate.selectedBidAmount = this.detailsForUpdate.suggestedBidAmount;
      const changeKeywordBidAmountReq = {
        orgId: this.detailsForUpdate.orgId,
        campaignId: this.detailsForUpdate.campaignId,
        adGroupId: this.detailsForUpdate.adGroupId,
        keywordBidAmount: {
          id: this.detailsForUpdate.keywordId,
          bidAmount: this.detailsForUpdate.selectedBidAmount,
        },
      };

      try {
        const result = await this.$apollo.mutate({
          mutation: CHANGE_KEYWORD_BID_AMOUNT,
          variables: changeKeywordBidAmountReq,
        });

        if (result.data.changeKeywordBidAmount.searchAdsErrors) {
          this.$notify.error(
            result.data.changeKeywordBidAmount.searchAdsErrors
          );
        } else {
          this.$notify.success(this.$t('successMsg.bidChangeSuccessMsg'));
          this.bidAmountDialog = false;
          this.$apollo.queries.targKeyReportsForKeyOpt.refetch();
        }
      } catch {}

      this.saving = false;
    },

    async updateAllBidAmount() {
      this.saving = true;
      let result;
      for (const key of this.keywordsReport.row) {
        let selectedBidAmount = key.insights.bidRecommendation.suggestedBidAmount;
        const changeKeywordBidAmountReq = {
          orgId: key.metadata.orgId,
          campaignId: key.metadata.campaignId,
          adGroupId: key.metadata.adGroupId,
          keywordBidAmount: {
            id: key.keywordMetadata.id,
            bidAmount: selectedBidAmount,
          },
        };

        try {
          result = await this.$apollo.mutate({
            mutation: CHANGE_KEYWORD_BID_AMOUNT,
            variables: changeKeywordBidAmountReq,
          });
        } catch {}
      }
      if (result.data.changeKeywordBidAmount.searchAdsErrors) {
        this.$notify.error(result.data.changeKeywordBidAmount.searchAdsErrors);
      } else if (result.data.changeKeywordBidAmount.successMessage) {
        this.$notify.success(this.$t('successMsg.bidChangeSuccessMsg'));
        this.bidAmountDialog = false;
        this.$apollo.queries.targKeyReportsForKeyOpt.refetch();
      } else {
        this.$notify.error(this.$t('successMsg.wentWrongMsg'));
      }
      this.saving = false;
      this.optimizeAllDialog = false;
    },
  },

  mounted() {
    this.syncStatus = LSS.activeSync;
    Vue.prototype.$sckt.on('syncprogress:event', () => {
      this.syncStatus = LSS.activeSync;
    });
    this.locale = localStorage.getItem('locale') || 'en';
    this.$root.$on('locale-changed', (locale) => {
      this.locale = locale.localeCode;
    });
  },
});
